import { React, ReactDOM, setOverrideAPI } from 'mcelroy-lib'
import { McElroyApp } from 'mcelroy-components'
import { Home } from './pages/Home'
import { Patent} from './pages/Patent'
import { CreatePatent } from './pages/CreatePatent'

API_OVERRIDE && setOverrideAPI(API_OVERRIDE)

const rootElement = document.getElementById('root')
const root = ReactDOM.createRoot(rootElement)

const hello = 'hello&reg;'

const routes = [
  {
    path: "/",
    element: <Home/>,
    handle: {
      title: 'Home'
    }
  },
  {
    path: "/patent/:id",
    element:<Patent/>

  },
  {
    path: "/createpatent",
    element:<CreatePatent/>
  },
  {
    path: "/test",
    element: <div dangerouslySetInnerHTML={{__html: hello}}></div>
  }
]

root.render(<McElroyApp title="Example" routes={routes} />)
