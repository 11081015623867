import { React, ReactRouter, useAPI } from 'mcelroy-lib'
import { FormPage, useToast } from 'mcelroy-components'

export const CreatePatent = () => {

  const toast = useToast()

  const { run: createPatent } = useAPI(`
  mutation($patent: PatentInput!) {
    createPatent(patent: $patent){
      id
    }
  }`)

  return (<FormPage
    title="Create Patent"
    fields={[
      {
        label: "Product",
        name: "product",
        value: ""
      },
      {
        label: "PartNumbers",
        name: "partNumbers",
        value: ""
      },
      {
        label: "Patent",
        name: "patents",
        value: ""
      }
    ]}
    buttons={[
      {
        label: "Save",
        primary: true,
        onClick: async (formData) => {
          try {
            const patent = { ...formData }
            await createPatent({ patent: patent })
            toast.success("Patent Created")
          }
          catch (error) {
            console.log(error)
            if(error) toast.error(error[0]?.message ?? 'Error creating patent')
            else toast.error('Error creating patent')
          }
        }
      }
    ]}
  >

  </FormPage>)
}


