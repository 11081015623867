import { React, ReactRouter, useAPI } from 'mcelroy-lib'
import { FormPage, useToast } from 'mcelroy-components'

export const Patent = () => {

  const toast = useToast()
  const routeParams = ReactRouter.useParams()
  const navigate = ReactRouter.useNavigate()

  const { loading, data } = useAPI(`
  query($id: Int!) {
    patent(id: $id){
      id
      product
      partNumbers
      patents
    }
  }`, { id: routeParams.id })

  const { run: updatePatent } = useAPI(`
  mutation ($patent: PatentInput!){
    updatePatent(patent:$patent
    ){
      id
    }
  }`)

  const {run: deletePatent} = useAPI(`
  mutation($id: Int!) {
    deletePatent(id: $id)
  }
  `)

  if (loading) return null

  return (<FormPage
    title="Edit Patent"
    fields={[
      {
        label: "Id",
        name: "id",
        value: data?.patent?.id
      },
      {
        label: "Product",
        name: "product",
        value: data?.patent?.product
      },
      {
        label: "PartNumbers",
        name: "partNumbers",
        value: data?.patent?.partNumbers
      },
      {
        label: "Patent",
        name: "patents",
        value: data?.patent?.patents
      }
    ]}
    buttons={[
      {
        label: "Save",
        primary: true,
        onClick: async (formData) => {
          try {
            const patent = { ...data?.patent, ...formData }
            await updatePatent({ patent: patent })
            toast.success("Patent Saved")
          }
          catch (error) {
            console.log(error)
            if(error) toast.error(error[0]?.message ?? 'Error updating patent')
            else toast.error('Error updating patent')
          }
        }
      },
      {
        label: "Delete",
        caution: true,
        onClick: async (formData) => {
          try {
            await deletePatent({ id: data.patent.id })
            toast.success("Patent Deleted")
            navigate('/')
          }
          catch (error) {
            console.log(error)
            if(error) toast.error(error[0]?.message ?? 'Error updating patent')
            else toast.error('Error updating patent')
          }
        }
      }
    ]}
  >

  </FormPage>)
}


