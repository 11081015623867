import { React, useAPI } from 'mcelroy-lib'
import { TablePage, useUserContext } from 'mcelroy-components'

export function Home() {
    const { user } = useUserContext()
    const { loading, data } = useAPI(`
    query {
        patents{
          id
          product
          partNumbers
          patents
        }
      }`)
    const columns = [
        { title: 'Product', name: 'product', allowUnsafeHTML: true },
        { title: 'Part Number', name: 'partNumbers', allowUnsafeHTML: true },
        { title: 'Patents', name: 'patents', allowUnsafeHTML: true }
    ]

    const options = {
        keyColumn: 'id'
    }
    const actions = []

    if (user.hasRoles(['PatentAdmin'])) {
        options.rowClickTo = '/patent/:id'
        actions.push({
            label: 'Create Patent',
            to: '/createpatent'
        })
    }

    return (
        <TablePage
            columns={columns}
            data={data?.patents}
            loading={loading}
            options={options}
            actions={actions}
        />
    )
}